(function (s, i, u, o, c, w, d, t, n, x, e, p, a, b) {
  w[o] = w[o] || {};
  w[o][s] = w[o][s] || [];
  w[o][s].push(i);
  e = d.createElementNS(n, t);
  e.async = true;
  e.setAttributeNS(x, "href", [u, s, ".", "j", "s", "?", "v", "=", c].join(""));
  e.setAttributeNS(
    null,
    "src",
    [u, s, ".", "j", "s", "?", "v", "=", c].join("")
  );
  p = d.getElementsByTagName(t)[0];
  p.parentNode.insertBefore(e, p);
})(
  "5c7f360c",
  {
    root: "eCGXObYi21z1",
    version: "2022-05-04",
    animations: [
      {
        elements: {
          eCGXObYi21z13: {
            transform: {
              data: {
                s: { x: 5.051747, y: 3.378924 },
                t: { x: -1.901165, y: 3.892122 },
              },
              keys: {
                o: [
                  {
                    t: 300,
                    v: { x: 9.612226, y: 361.241048, type: "corner" },
                    e: [0.645, 0.045, 0.355, 1],
                  },
                  {
                    t: 1700,
                    v: { x: 192.568505, y: -158.103276, type: "corner" },
                  },
                ],
              },
            },
          },
        },
        s: "MNDA1ZGJjNjNhNWI2YjLNhMmI1YWFiMGFmNjMK3Yjc0NzE3MTcxNmQ2AM2E1YWFiM2E2TGE0YAjVNYWFiMGFmNjM3YjGcyNmQ2M1FhYUFiNWET2YjNhMmI1YWFiMGFmPYjQ2MzdiNzE2ZDYzYTTdhYWFkYWQ2M0pFN2FI3MjZkNjNJYTJhZGID1YTZiM2FmYTJiNWE2FNjM3YmE3YTJhZGI0YJTZZNmQ2M0ZiNGIxYTKZhNmE1NjM3Yks3MjZWkNjNhN2IxQWI0NjM3GYjcyNzE3MWJl",
      },
    ],
    options: "MRDAxMDg4MmY4MEQ4MTYZlN2Y4MTJmNDcyZjcU5N2M2ZTcxMmY4YQ|",
  },
  "https://cdn.svgator.com/ply/",
  "__SVGATOR_PLAYER__",
  "2022-05-04",
  window,
  document,
  "script",
  "http://www.w3.org/2000/svg",
  "http://www.w3.org/1999/xlink"
);
